@media (max-width: 768px) {
  body{font-size: .8em;}
  h1{font-size: 1.4em; padding-bottom: .5em; padding: .2em}
  h4{font-size: 1em !important; font-weight: 90px; height: 20px}
  p{font-size: .8em;}
  .card-offer{

  	h4{font-weight: 900;}
  	.fa{color: white; font-size: 2.2em}
  	.duration{float: right; margin-top: 0px; font-size: .8em}
  		.days{background: #ebff00; color: black;padding: 2px}
  		.nights{color: #ebff00; background: black;padding: 2px}
  		box-shadow: none;
       transition: box-shadow 0.4s;
  }
  .tprice{

    //display: none !important;
  }
  .page-header{margin-top: 0px;}
  .slide{ width: 100%; height: 520px;
    .carousel-indicators li{display: none;}
    .carousel-indicators  li.active{display: none;}
  }
  .col-md-12, .col-lg-8, .tab-content{padding: 0px ; margin: 0px;}
  //home
    .carousel-caption{width: 90%;}
  	.carousel-indicators li{display: none;}
  	.carousel-indicators  li.active{display: none; margin-left: 10px;}

  //
  .col-xs-6{padding: 4px;}

  .bgn{font-weight: 100; text-transform: none;}
  .price-grand-total{ font-weight: 900; font-size: 1.1em !important;  color: white; background: $color-primary; padding: 13px; margin-right: -1px;}
  .price-grand-total:after{ content: " лв"; font-weight: 100; font-size: 0.8em !important}

}
